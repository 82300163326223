import * as React from 'react'
import type { AppProps } from 'next/app'
import { SessionProvider } from 'next-auth/react'
import { Toaster } from 'react-hot-toast'
import { ReactQueryDevtools } from 'react-query/devtools'
import { QueryClient, QueryClientProvider } from 'react-query'
import { AnalyticsAndConsent } from 'lib/analytics'
import { AppProvider } from '../lib/shared-ui'

import 'lib/styles/global.css'
import 'mapbox-gl/dist/mapbox-gl.css'
import { AppTourProvider } from '../lib/tour/context'
import { AttributionProvider } from '../lib/use-attribution'

const queryClient = new QueryClient()

export default function App({
    Component,
    pageProps: { session, ...pageProps },
}: AppProps) {
    return (
        <SessionProvider session={session}>
            <AttributionProvider>
                <AppProvider>
                    <AppTourProvider>
                        <QueryClientProvider client={queryClient}>
                            <ReactQueryDevtools
                                initialIsOpen={false}
                                position="top-left"
                            />

                            <AnalyticsAndConsent />
                            {/*<Script src="//cdn-4.convertexperiments.com/js/10042837-10043774.js" />*/}
                            <Component {...pageProps} />
                        </QueryClientProvider>
                        <Toaster toastOptions={{ duration: 5000 }} />
                    </AppTourProvider>
                </AppProvider>
            </AttributionProvider>
        </SessionProvider>
    )
}
