import Cookies from 'js-cookie'
import { useRouter } from 'next/router'
import * as React from 'react'

function getSetCookie(name: string, newValue?: string) {
    const currentValue = Cookies.get(name)
    if (currentValue) return currentValue

    if (newValue) {
        Cookies.set(name, newValue, { domain: '.relocify.nl' })
        return newValue
    }

    return null
}

const AttributionContext = React.createContext<{
    utmSource: string | null
    utmMedium: string | null
    utmCampaign: string | null
    utmTerm: string | null
    utmContent: string | null
    utmDevice: string | null
    referrer: string | null
}>({
    utmSource: null,
    utmMedium: null,
    utmCampaign: null,
    utmTerm: null,
    utmContent: null,
    utmDevice: null,
    referrer: null,
})

export const AttributionProvider: React.FC = ({ children }) => {
    const router = useRouter()
    const utmSource = getSetCookie(
        'utm_source',
        router.query.utm_source as string
    )
    const utmMedium = getSetCookie(
        'utm_medium',
        router.query.utm_medium as string
    )
    const utmCampaign = getSetCookie(
        'utm_campaign',
        router.query.utm_campaign as string
    )
    const utmTerm = getSetCookie('utm_term', router.query.utm_term as string)
    const utmContent = getSetCookie(
        'utm_content',
        router.query.utm_content as string
    )
    const utmDevice = getSetCookie(
        'utm_device',
        router.query.utm_device as string
    )
    const referrer =
        typeof window !== 'undefined'
            ? getSetCookie('referrer', getRootDomain(document.referrer))
            : null

    return (
        <AttributionContext.Provider
            value={{
                utmSource,
                utmMedium,
                utmCampaign,
                utmTerm,
                utmContent,
                utmDevice,
                referrer,
            }}
        >
            {children}
        </AttributionContext.Provider>
    )
}

export function useAttribution() {
    return React.useContext(AttributionContext)
}

function getRootDomain(url: string) {
    if (!url) return ''

    return (
        url
            .match(/^https?:\/\/([^\/?#]+)(?:[\/?#]|$)/i)?.[1]
            ?.split('.')
            ?.slice(-2)
            ?.join('.') ?? ''
    )
}
