import { useUser } from '../auth'
import React, { useState, useEffect } from 'react'
import { useRouter } from 'next/router'
import TagManager from 'react-gtm-module'
import Cookies from 'js-cookie'
import * as amplitude from '@amplitude/analytics-browser'
import config from 'config'

const COOKIE_NAME = 'userConsent'
import { sessionReplayPlugin } from '@amplitude/plugin-session-replay-browser'
import { useApp } from '../shared-ui'
const sessionReplayTracking = sessionReplayPlugin({ debugMode: true })
amplitude.add(sessionReplayTracking)

// Initialize Amplitude
function initAmplitude(apiKey: string, userId?: string) {
    amplitude.init(apiKey, userId, {
        autocapture: true,
        // serverZone: 'EU',
    })
}

// Log an event to both Amplitude and GTM
export function logEvent(name: string, props?: Record<string, any>) {
    amplitude.logEvent(name, props || {})
    TagManager.dataLayer({
        dataLayer: {
            event: name,
            ...props,
        },
    })
}

// Optional hook to log an event after component render
export function useLogOnRender(name: string, props?: Record<string, any>) {
    const router = useRouter()
    const { isLoading } = useUser()
    const log = () => {
        setTimeout(() => {
            logEvent(name, props || {})
        }, 500)
    }

    React.useEffect(() => {
        if (router.isReady && !isLoading) {
            log()
        }
    }, [router.isReady, name, isLoading])
}

export function resetConsent() {
    Cookies.remove(COOKIE_NAME, { domain: '.relocify.nl' })
    Cookies.remove(COOKIE_NAME)
    setTimeout(() => window.location.reload(), 100)
}

interface SwitchProps {
    id: string
    label: string
    description?: string
    defaultValue: boolean
    onChange: (val: boolean) => void
}

function Switch({
    id,
    label,
    description,
    defaultValue,
    onChange,
}: SwitchProps) {
    const [enabled, setEnabled] = useState(defaultValue)
    const toggle = () => {
        const nextVal = !enabled
        setEnabled(nextVal)
        onChange(nextVal)
    }

    return (
        <div className="flex items-center justify-between rounded-md border border-slate-700 bg-slate-800 p-3">
            <div>
                <p className="font-medium text-white">{label}</p>
                {description && (
                    <p className="text-sm text-gray-400">{description}</p>
                )}
            </div>
            <button
                id={id}
                onClick={toggle}
                className={`relative flex h-6 w-12 items-center rounded-full p-1 transition-all ${
                    enabled ? 'bg-green-500' : 'bg-gray-500'
                }`}
            >
                <div
                    className={`h-5 w-5 transform rounded-full bg-white shadow-md transition-transform ${
                        enabled ? 'translate-x-6' : 'translate-x-0'
                    }`}
                />
            </button>
        </div>
    )
}

interface ConsentState {
    ad_storage: boolean
    analytics_storage: boolean
}

export function AnalyticsAndConsent() {
    const router = useRouter()
    const [consent, setConsent] = useState<ConsentState | null>(null)
    const [showBanner, setShowBanner] = useState(false)
    const [showCustomization, setShowCustomization] = useState(false)
    const { isAuth, isLoading, ...user } = useUser()
    const { platform } = useApp()

    useEffect(() => {
        if (!router.isReady || isLoading || !config.analyticsEnabled) return

        let defaultConsentStates = {
            ad_storage: 'denied',
            analytics_storage: 'denied',
        }

        const savedConsent = Cookies.get(COOKIE_NAME)
        if (savedConsent) {
            try {
                const parsed = JSON.parse(savedConsent)
                defaultConsentStates = {
                    ad_storage: parsed.ad_storage ? 'granted' : 'denied',
                    analytics_storage: parsed.analytics_storage
                        ? 'granted'
                        : 'denied',
                }

                setConsent({
                    ad_storage: !!parsed.ad_storage,
                    analytics_storage: !!parsed.analytics_storage,
                })
                setShowBanner(false)
            } catch (e) {
                setShowBanner(true)
                setConsent({
                    ad_storage: false,
                    analytics_storage: false,
                })
            }
        } else {
            setShowBanner(true)
            setConsent({
                ad_storage: false,
                analytics_storage: false,
            })
        }

        TagManager.initialize({
            gtmId: config.gtmId,
            dataLayer: {
                event: 'update_consent',
                ...defaultConsentStates,
                authorized: isAuth,
                userId: user.id || null,
                userName: user.name || null,
                userRole: user.role || null,
                userEmail: user.email || null,
                platform,
            },
        })

        if (platform === 'web') {
            initAmplitude(config.amplitude.apiKey, user.email)
        }
    }, [router.isReady])

    const updateConsent = (category: keyof ConsentState, value: boolean) => {
        setConsent((prev) => (prev ? { ...prev, [category]: value } : null))
    }

    const saveConsent = (updatedConsent: ConsentState) => {
        Cookies.set(COOKIE_NAME, JSON.stringify(updatedConsent), {
            expires: 365,
            domain: '.relocify.nl',
            secure: true,
            sameSite: 'Lax',
        })

        logEvent('update_consent', {
            ad_storage: updatedConsent.ad_storage ? 'granted' : 'denied',
            analytics_storage: updatedConsent.analytics_storage
                ? 'granted'
                : 'denied',
        })
        setShowBanner(false)
    }

    const handleAcceptAll = () => {
        saveConsent({
            ad_storage: true,
            analytics_storage: true,
        })
    }

    const handleRejectAll = () => {
        saveConsent({
            ad_storage: false,
            analytics_storage: false,
        })
    }

    if (!consent || !showBanner) return null

    return (
        <>
            <div className="fixed inset-0 z-[9999998] bg-black bg-opacity-50" />
            <div className="fixed inset-0 z-[9999999] flex items-center justify-center">
                <div className="mx-3 max-w-2xl rounded-lg bg-slate-900 p-5 text-white">
                    <h2 className="text-lg font-semibold">
                        🍪 Did someone say … cookies?
                    </h2>
                    <p className="text-sm text-gray-400">
                        This site uses cookies to improve your experience. See
                        our{' '}
                        <a
                            href={config.legal.privacy}
                            className="text-indigo-300 underline"
                        >
                            Privacy Policy
                        </a>
                        .
                    </p>

                    {showCustomization && (
                        <div className="mt-3 space-y-2">
                            <Switch
                                id="analytics_storage"
                                label="Analytics Cookies"
                                description="Help us improve by collecting anonymous usage data"
                                defaultValue={consent.analytics_storage}
                                onChange={(val) =>
                                    updateConsent('analytics_storage', val)
                                }
                            />
                            <Switch
                                id="ad_storage"
                                label="Advertising Cookies"
                                description="Used for personalized ads and ad measurement"
                                defaultValue={consent.ad_storage}
                                onChange={(val) =>
                                    updateConsent('ad_storage', val)
                                }
                            />
                        </div>
                    )}

                    <div className="mt-4 flex justify-end space-x-3">
                        {!showCustomization ? (
                            <button
                                onClick={() => setShowCustomization(true)}
                                className="rounded-md bg-gray-600 px-4 py-2 text-white hover:bg-gray-700"
                            >
                                Customize
                            </button>
                        ) : (
                            <button
                                onClick={handleRejectAll}
                                className="rounded-md bg-gray-600 px-4 py-2 text-white hover:bg-gray-700"
                            >
                                Reject All
                            </button>
                        )}

                        <button
                            onClick={handleAcceptAll}
                            className="rounded-md bg-amber-300 px-4 py-2 font-medium text-amber-900"
                        >
                            Accept All
                        </button>
                    </div>
                </div>
            </div>
        </>
    )
}
